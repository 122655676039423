import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I participated in the `}<em parentName="p">{`2nd Ubuntu scopes showdown`}</em>{` competition for the previously
upcoming `}<ExternalLink href="https://en.wikipedia.org/wiki/Ubuntu_Touch" mdxType="ExternalLink">{`Ubuntu Touch`}</ExternalLink>{` project. It
was a fun side-project. I implemented a scope to interact with the freesound catalogue, a
collaborative online database of Creative Commons licensed sounds. This made it possible to search
and preview sounds straight from the scope, without neeeding a dedicated app.`}</p>
    <p>{`I ended up winning second place in the competition!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      